import React, { useState } from 'react';
import '../css/GuidePanel.css'; // You can create a separate CSS file for styling
import Dashboard from "./dashboard"

export const Guide = (props) => {
    var uuid = localStorage.getItem("auth")
    const [apiCode, setApiCode] = useState(`
INSTALLATION
npm install axios--save
  
REQUEST
const axios = require('axios')

const authID = 'your-auth-id'
const authPass = 'your-auth-password'
const base64Credentials = Buffer.from('authID:authPass').toString('base64')

const url = 'https://whatsapp.dodaily.pk/app/v1/messages?UID=${uuid ? uuid : "{your-user-ID}"}'

const body = {
    "recipient": "923001234567",
    "message": "Example",
    "messageType": "text/document/image",
    "url": "https://www.africau.edu/images/default/sample.pdf",
    "filename": "12345_Doc"
}

// If messageType = text, only 3 parameters are needed i.e. recipient, message, and messageType.
// If messageType = document/image, 5 parameters will be needed, including above 3 and a valid url and a filename.

const headers = {
    "token": "your-secret-token", // Replace with your actual secret token
    "authorization": Basic base64Credentials,
}

axios.post(url, body, { headers })
    .then(resp => console.log(resp))
    .catch(err => console.error('error:', err))
`)

    const handleApiCodeChange = (event) => {
        setApiCode(event.target.value);
    }

    const generateGuide = () => {
        return (
            <div className="guide">
                {/* <h2>Your API Guide</h2> */}
                <p>After a successfull registration and activation, the above mentioned API can be used to send WhatsApp messages.</p>
            </div>
        );
    };

    return (
        <div className="guide-panel">
            <div className="code-input">
                <label htmlFor="apiCode">Node.js</label>
                <textarea
                    id="apiCode"
                    value={apiCode}
                    onChange={handleApiCodeChange}
                    placeholder="Paste your API code here..."
                />
            </div>
            {apiCode && generateGuide()}
        </div>
    )
}
