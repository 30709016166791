import { BrowserRouter } from "react-router-dom"
import "./App.css"
import { AuthWrapper } from "./auth/AuthWrapper"

function App() {
  return (
    <div className="App" style={{ display: "flex" }}>
      <BrowserRouter basename="/">
        <AuthWrapper />
      </BrowserRouter>
    </div>
  )
}

export default App;