import { useReducer, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AuthData } from "../../auth/AuthWrapper"
import { MDBBtn, MDBInput } from 'mdb-react-ui-kit';
import '../css/login.css';

export const Login = () => {

     const navigate = useNavigate();
     const { login } = AuthData();
     const [formData, setFormData] = useReducer((formData, newItem) => { return ({ ...formData, ...newItem }) }, { userName: "", password: "" })
     const [errorMessage, setErrorMessage] = useState(null)

     const doLogin = async () => {
          try {
               let resp = await login(formData.userName, formData.password)
               if (resp.type == "admin") {
                    navigate("/admin")
               }
               else {
                    navigate("/dashboard")
               }
          } catch (error) {
               setErrorMessage(error)
          }
     }

     return (
          <div style={{ display: "inline-block", flex: 1, verticalAlign: "top", fontFamily: "calibri", alignContent: "center" }}>
               <div className="loginDiv"
                    style={{
                         border: "1px solid lightgrey",
                         borderRadius: 5,
                         margin: "100px auto",
                    }}>
                    <br />

                    <div className="d-flex flex-row align-items-center justify-content-center">
                         <p className="lead fw-normal mb-0 me-3">Sign in</p>
                    </div>
                    <br />

                    <MDBInput wrapperClass='mb-4' label='User Name' id='formControlLg' size="lg" value={formData.userName} onChange={(e) => setFormData({ userName: e.target.value })} type="text" />
                    <MDBInput wrapperClass='mb-4' label='Password' id='formControlLg1' type='password' size="lg" value={formData.password} onChange={(e) => setFormData({ password: e.target.value })} />

                    {/* <div className="d-flex justify-content-between mb-4">
                              <MDBCheckbox name='flexCheck' value='' id='flexCheckDefault' label='Remember me' />
                              <a href="!#">Forgot password?</a>
                         </div> */}

                    <div className='text-center text-md-start mt-4 pt-2'>
                         <MDBBtn className="mb-0 px-5" size='lg' style={{ backgroundColor: "#075e54" }} onClick={doLogin}>Login</MDBBtn>
                    </div>
                    <br />
                    {errorMessage ?
                         <div className="error">{errorMessage}</div>
                         : null}
                    <br />

               </div>
          </div>
     )
}