import { Route, Routes, useLocation, NavLink } from "react-router-dom";
import { AuthData } from "../auth/AuthWrapper"
import { nav } from "./navigation";
import React, { useState, useEffect } from "react";
import {
     CDBSidebar,
     CDBSidebarContent,
     // CDBSidebarFooter,
     CDBSidebarHeader,
     CDBSidebarMenu,
     CDBSidebarMenuItem,
} from 'cdbreact';

export const RenderRoutes = () => {
     const { user } = AuthData()
     return (
          <Routes>
               {nav.map((r, i) => {
                    if (user.type === "admin") {
                         if (r.isPrivate && user.isAuthenticated && localStorage.getItem("auth") && r.type === "admin") {
                              return <Route key={i} path={r.path} element={r.element} />
                         }
                    }
                    else {
                         if (r.isPrivate && user.isAuthenticated && localStorage.getItem("auth") && r.type !== "admin") {
                              return <Route key={i} path={r.path} element={r.element} />
                         }
                         else if (!r.isPrivate) {
                              return <Route key={i} path={r.path} element={r.element} />
                         }
                         else {
                              return false
                         }
                    }
               })}

          </Routes>
     )
}

export const RenderMenu = () => {

     const location = useLocation();
     const { user, logout } = AuthData()
     const [activeLink, setActiveLink] = useState(null)

     function handleLinkClick(path) {
          setActiveLink(path)
     };

     useEffect(() => {
          setActiveLink(location.pathname)
     });

     function logoutfunc() {
          setActiveLink(null)
          logout()
     }

     return (
          <CDBSidebar textColor="#fff" maxWidth="220px" minWidth="58px" breakpoint={1000} backgroundColor="#075e54" style={{ display: "inline-block", height: "100vh", position: "sticky", top: 0 }} >

               <CDBSidebarHeader
                    prefix={<i className="fa fa-bars fa-large"></i>}
               >
                    <a href="/" className="text-decoration-none" style={{ color: 'inherit', fontFamily: "calibri" }}>
                         Send WhatsApp
                    </a>
               </CDBSidebarHeader>

               <CDBSidebarContent className="sidebar-content">
                    <CDBSidebarMenu>
                         <div className="menu" style={{ fontFamily: "calibri" }}>
                              {user.isAuthenticated && localStorage.getItem("auth") ?
                                   ""
                                   :
                                   <div>
                                        <NavLink exact to="/" style={{ fontWeight: "/" === activeLink ? "bold" : "" }} activeClassName="activeClicked">
                                             <CDBSidebarMenuItem icon="user">Login</CDBSidebarMenuItem>
                                        </NavLink>
                                        <NavLink exact to="/signup" style={{ fontWeight: "/signup" === activeLink ? "bold" : "" }} activeClassName="activeClicked">
                                             <CDBSidebarMenuItem icon="user">Sign up</CDBSidebarMenuItem>
                                        </NavLink>
                                   </div>
                              }
                              {nav.map((r, i) => {
                                   if (user.type === "admin") {
                                        if (user.isAuthenticated && r.isMenu && r.name && localStorage.getItem("auth") && r.type === "admin") {
                                             return (
                                                  <NavLink exact to={r.path} style={{ fontWeight: r.path === activeLink ? "bold" : "" }} onClick={() => handleLinkClick(r.path)} activeClassName="activeClicked">
                                                       <CDBSidebarMenuItem icon="sticky-note">{r.name}</CDBSidebarMenuItem>
                                                  </NavLink>
                                             )
                                        }
                                   }
                                   else {
                                        if (!r.isPrivate && r.isMenu && r.name) {
                                             return (
                                                  <NavLink exact to={r.path} style={{ fontWeight: r.path === activeLink ? "bold" : "" }} onClick={() => handleLinkClick(r.path)} activeClassName="activeClicked">
                                                       <CDBSidebarMenuItem icon="sticky-note">{r.name}</CDBSidebarMenuItem>
                                                  </NavLink>
                                             )
                                        } else if (user.isAuthenticated && r.isMenu && r.name && localStorage.getItem("auth") && r.type !== "admin") {
                                             return (
                                                  <NavLink exact to={r.path} style={{ fontWeight: r.path === activeLink ? "bold" : "" }} onClick={() => handleLinkClick(r.path)} activeClassName="activeClicked">
                                                       <CDBSidebarMenuItem icon="sticky-note">{r.name}</CDBSidebarMenuItem>
                                                  </NavLink>
                                             )
                                        } else return false
                                   }
                              })}
                              {user.isAuthenticated && localStorage.getItem("auth") ?
                                   <NavLink
                                        // exact to="/"
                                        onClick={() => logoutfunc()} activeClassName="activeClicked">
                                        <CDBSidebarMenuItem icon="user">Logout</CDBSidebarMenuItem>
                                   </NavLink>
                                   :
                                   ""
                              }
                         </div>
                    </CDBSidebarMenu>
               </CDBSidebarContent>

               {/* <CDBSidebarFooter style={{ textAlign: 'center' }}>
                    <div
                         style={{
                              padding: '20px 5px',
                              fontFamily: "calibri"
                         }}
                    >
                         <a
                              href="https://simplesolutions.pk"
                              style={{ textDecoration: "none", color: "white" }}
                         >
                              Developed by Simple Solutions
                         </a>
                    </div>
               </CDBSidebarFooter> */}

          </CDBSidebar>
     )
}