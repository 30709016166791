import React from 'react';
import '../css/PricingTable.css';

export const Pricing = () => {
  return (
    <div className="pricing-table-container">
      <table className="pricing-table">
        <thead>
          <tr>
            <th>Package</th>
            <th>Messages per Month</th>
            <th>Price per Month</th>
            <th>Additional Features</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Starter</td>
            <td>Up to 1,000</td>
            <td>$20</td>
            <td>Basic API Access</td>
          </tr>
          <tr>
            <td>Basic</td>
            <td>Up to 5,000</td>
            <td>$50</td>
            <td>Priority Support, Delivery Reports</td>
          </tr>
          <tr>
            <td>Pro</td>
            <td>Up to 10,000</td>
            <td>$80</td>
            <td>Message Scheduling, Customized Sender ID</td>
          </tr>
          <tr>
            <td>Business</td>
            <td>Up to 50,000</td>
            <td>$150</td>
            <td>Dedicated Account Manager, API Analytics</td>
          </tr>
          <tr>
            <td>Enterprise</td>
            <td>50,000+</td>
            <td>Custom Quote</td>
            <td>Custom SLA, High Priority Support, Integration Assistance</td>
          </tr>
        </tbody>
      </table>
    </div>
  )
}