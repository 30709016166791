import axios from "axios";
// import { AuthData } from "../../../auth/AuthWrapper";
import { useState, useEffect } from "react";
import { Loader } from 'rsuite';
import 'rsuite/dist/rsuite.min.css';
import { MDBInput } from 'mdb-react-ui-kit';
import '../css/pages.css';
import Switch from '@mui/material/Switch';
import formdate from 'date-and-time'
import { MDBBtn } from 'mdb-react-ui-kit';
import Modal from 'react-modal';

export const Dashboard = () => {

    Modal.setAppElement(Dashboard)

    // var headers = new fetch.Headers()

    // headers.set('Authorization', 'Basic ' + base64.encode(123 + ":" + 123));

    // const config = {
    //     headers: { Authorization: 'Basic ' + base64.encode(123 + ":" + 123) }
    //   };

    // function getWhatsApp() {
    //     axios.get("https://whatsapp.dodaily.pk/app/getWhatsapp")
    //         .then((res) => console.log(res))
    // }

    // const { user } = AuthData();
    // const riDate = changeTimeZone(new Date(), 'Asia/Riyadh')
    // let today = formdate.format(riDate, 'YYYY-MM-DD')

    const [startDate, setStartDate] = useState()
    const [endDate, setEndDate] = useState()
    const [startDateReceipt, setStartDateReceipt] = useState()
    const [endDateReceipt, setEndDateReceipt] = useState()
    const [password, setPassword] = useState("")
    const [messages, setMessages] = useState([{}])
    const [test, settest] = useState({ testNumber: null, testMessage: null, UID: localStorage.getItem("auth") })
    const [receipts, setReceipts] = useState([{}])
    const [load, setLoad] = useState(false)
    const [clientData, setClientData] = useState([{}])
    const [token, setToken] = useState()
    const [whatsappNumber, setWhatsappNumber] = useState()
    const label = { inputProps: { 'aria-label': 'Switch demo' } }
    const [modalIsOpen, setIsOpen] = useState(false)
    const [modalIsOpen1, setIsOpen1] = useState(false)
    let count = 1

    const currentDate = new Date()
    const khiDate = changeTimeZone(currentDate, 'Asia/Karachi')
    let endDatee = formdate.format(khiDate, 'YYYY-MM-DD')
    let endDateeReceipt = formdate.format(khiDate, 'YYYY-MM-DD')

    currentDate.setDate(1)
    let startDatee = formdate.format(currentDate, 'YYYY-MM-DD')
    let startDateeReceipt = formdate.format(currentDate, 'YYYY-MM-DD')

    const customStyles = {
        content: {
            top: '40%',
            left: '50%',
            right: '20%',
            bottom: '-10%',
            marginRight: '-20%',
            transform: 'translate(-50%, -50%)',
        },
    }

    const tokenTemplate = (
        <div>
            <textarea
                value={token}
                style={{ width: 500, height: 150 }}
            >
            </textarea>
            <p style={{ color: "red" }}>Please save this token safely as it will not appear again when you refersh the page</p>
        </div>
    )

    Modal.setAppElement('#root')

    function changeTimeZone(date, timeZone) {
        if (typeof date === 'string') {
            return new Date(
                new Date(date).toLocaleString('en-US', {
                    timeZone,
                }),
            );
        }

        return new Date(
            date.toLocaleString('en-US', {
                timeZone,
            }),
        );
    }

    function openModal() {
        setIsOpen(true)
    }

    function openModal1() {
        setIsOpen1(true)
    }

    function closeModal() {
        setIsOpen(false)
    }

    function closeModal1() {
        setIsOpen1(false)
    }

    function callingServer() {
        axios.post(`https://whatsapp.dodaily.pk/app/getServer`, {
            uid: localStorage.getItem("auth")
        })
            .then((res) => {
                // console.log(res.data.result)
                if (res.data.result.length !== 0) {
                    const filteredResult = res.data.result
                        .map((obj) => ({
                            ...obj,
                            newDate: obj.time ? formdate.format(
                                changeTimeZone(obj.time, 'Asia/Karachi'),
                                'DD-MM-YYYY hh:mm:ss A'
                            )
                                :
                                ""
                        }))
                        .sort((a, b) => {
                            const dateA = new Date(a.time)
                            const dateB = new Date(b.time)

                            return dateB - dateA
                        })
                    // setClient(true)
                    setClientData(filteredResult)
                }
            })
    }

    function saveNumber() {
        const obj = {
            number: whatsappNumber,
            UID: localStorage.getItem("auth")
        }
        if (whatsappNumber.length === 12) {
            axios.post(`https://whatsapp.dodaily.pk/app/setNumber`, obj)
                .then((res) => {
                    if (res.data.affectedRows == 1 && res.data.changedRows == 1) {
                        callingServer()
                        alert("Number saved successfully.")
                    }
                    else {
                        alert("Something went wrong. Please try again.")
                    }
                })
        }
        else {
            alert("Please type complete number having 12 digits including country code")
        }
    }

    useEffect(() => {
        callingServer()
        setStartDate(startDatee)
        setEndDate(endDatee)
        setStartDateReceipt(startDateeReceipt)
        setEndDateReceipt(endDateeReceipt)
    }, [count])
    // })

    function sendtestmessage() {
        try {
            if (test.testNumber && test.testMessage) {
                axios.post("https://whatsapp.dodaily.pk/app/sendtestmessage", {
                    test
                }).then((response) => {
                    if (response.data.error) {
                        alert(response.data.error)
                    }
                    else {
                        callingServer()
                        settest({ testMessage: "", testNumber: "" })
                        alert(response.data.message)
                    }
                })
            }
            else {
                alert("Please type number and message")
            }
        } catch (error) {
            alert(error)
        }
    }

    function createToken() {
        try {
            if (password) {
                axios.post("https://whatsapp.dodaily.pk/app/token", {
                    UID: localStorage.getItem("auth"),
                    Password: password
                }).then((response) => {
                    setPassword("")
                    if (response.data.error) {
                        alert(response.data.error)
                    }
                    else {
                        setToken(response.data.token)
                        alert(response.data.message)
                    }
                })
            }
            else {
                alert("Please type password")
            }
        } catch (error) {
            alert(error)
        }
    }

    function callingFilteredSMS() {
        axios.post("https://whatsapp.dodaily.pk/app/getMessageList", {
            UID: localStorage.getItem("auth"),
            startDate: startDate,
            endDate: endDate,
        })
            .then(res => {
                if (res.data.data.length != 0) {
                    const filteredMessages = res.data.data
                        .map((obj) => ({
                            ...obj,
                            newDate: obj.time ? formdate.format(
                                changeTimeZone(obj.time, 'Asia/Karachi'),
                                'DD-MM-YYYY hh:mm:ss A'
                            )
                                :
                                ""
                        }))
                        .sort((a, b) => {
                            const dateA = new Date(a.time)
                            const dateB = new Date(b.time)

                            return dateB - dateA
                        })
                    setMessages(filteredMessages)
                    setLoad(true)
                }
                else {
                    setMessages([{}])
                    alert("No Message found in the selected criteria.")
                    setLoad(false)
                }
            })
    }

    function countSent() {
        var sent = 0
        messages.map((v, i) => {
            if (v.status) {
                if (v.status == 1) {
                    sent = sent + 1
                }
            }
        })
        return sent
    }

    // function countPending() {
    //     var pend = 0
    //     data.map((v, i) => {
    //         if (v.status) {
    //             if (v.status == 0) {
    //                 pend = pend + 1
    //             }
    //         }
    //     })
    //     return pend
    // }

    function countUnsend() {
        var unsend = 0
        messages.map((v, i) => {
            if (v.status == 0) {
                unsend = unsend + 1
            }
        })
        return unsend
    }

    function getReceipts() {
        try {
            // if (test.testNumber && test.testMessage) {
            axios.post("https://whatsapp.dodaily.pk/app/getReceipts", {
                UID: localStorage.getItem("auth"),
                startDate: startDateReceipt,
                endDate: endDateReceipt
            }).then((response) => {
                if (response.data.data.length > 0) {
                    if (response.data.error) {
                        alert(response.data.error)
                    }
                    else {
                        setReceipts(response.data.data)
                    }
                }
                else {
                    setReceipts([{}])
                    alert("No record found in the selected criteria.")
                }
            })
        }
        // else {
        //     alert("Please type number and message")
        // }
        // } 
        catch (error) {
            alert(error)
        }
    }
    // console.log(password)
    return (
        <div className="page" style={{ fontFamily: "calibri", flex: 1 }}>
            <h2>Dashboard</h2>
            <div className="subDiv">
                {/* <button onClick={() => getWhatsApp()}>Send Messages</button> */}
                <div className="search">
                    Profile
                    <table>
                        <tbody>
                            <tr style={{ lineHeight: 0 }}>
                                <td style={{ textAlign: "left", paddingRight: 10 }}>User Name: </td>
                                <td style={{ textAlign: "left" }}>{clientData[0].Client_Name}</td>
                            </tr>
                            <tr style={{ lineHeight: 0 }}>
                                <td style={{ textAlign: "left", paddingRight: 10 }}>Active (API): </td>
                                <td style={{ textAlign: "left" }}>{clientData[0].Is_Activate == 0 ? "No" : "Yes"}</td>
                            </tr>
                            <tr style={{ lineHeight: 0 }}>
                                <td style={{ textAlign: "left", paddingRight: 10 }}>WhatsApp No: </td>
                                <td style={{ textAlign: "left" }}>{clientData[0].WhatsApp_Number ? clientData[0].WhatsApp_Number : "Not Found"}</td>
                            </tr>
                            <tr style={{ lineHeight: 0 }}>
                                <td style={{ textAlign: "left", paddingRight: 10 }}>Test WhatsApp:</td>
                                <td style={{ textAlign: "left" }}>{clientData[0].Test_WhatsApp_Remaining + " Remaining"}</td>
                            </tr>
                            <tr style={{ lineHeight: 0 }}>
                                <td style={{ textAlign: "left", paddingRight: 10 }}>Credit:</td>
                                <td style={{ textAlign: "left" }}>{clientData[0].Account_Balance ? clientData[0].Account_Balance + " PKR" : 0}</td>
                            </tr>
                        </tbody>
                    </table>
                    <p style={{ fontWeight: "bold", textAlign: "left", fontSize: 14, paddingBottom: 0, marginBottom: 0, marginTop: 10 }}>Summary for { }</p>
                    <table className="table table-bordered table-sm" id="table-stats">
                        <thead>
                            <tr>
                                <th scope="col" style={{ fontWeight: "bold" }}>Total</th>
                                <th scope="col" style={{ fontWeight: "bold" }}>Sent</th>
                                <th scope="col" style={{ fontWeight: "bold" }}>Failed/Pending</th>
                                {/* <th scope="col" style={{ fontWeight: "bold" }}>Pending</th> */}
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <th scope="row" style={{ fontWeight: "bold" }}>{load ? messages.length : load && 0}</th>
                                <td style={{ fontWeight: "bold" }}>{
                                    load ? countSent() : load && 0
                                }</td>
                                <td style={{ fontWeight: "bold" }}> {
                                    load ? countUnsend() : load && 0
                                }
                                </td>
                                {/* <td style={{ fontWeight: "bold" }}> {
                                    load ? countPending() : load && 0
                                }
                                </td> */}
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div className="mainsearchcontainer">
                    Send Test Message
                    <table>
                        <tbody>
                            <tr style={{ lineHeight: 0 }}>
                                <td style={{ textAlign: "left", paddingRight: 10 }}>Recipient: </td>
                                <td style={{ textAlign: "left" }}><input value={test.testNumber} onChange={(e) => settest({ ...test, testNumber: e.target.value })} /></td>
                            </tr>
                            <tr style={{ lineHeight: 0 }}>
                                <td style={{ textAlign: "left", paddingRight: 10 }}>Message: </td>
                                <td style={{ textAlign: "left" }}><input value={test.testMessage} onChange={(e) => settest({ ...test, testMessage: e.target.value })} /></td>
                            </tr>
                            <tr style={{ lineHeight: 0 }}>
                                <td style={{ textAlign: "left", paddingRight: 10 }}></td>
                                <td style={{ textAlign: "left" }}><button onClick={sendtestmessage}>Send Messages</button></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div className="setting">
                    Search
                    <table style={{ marginBottom: 15 }}>
                        <tr>
                            <td>
                                Start Date:
                            </td>
                            <td>
                                <input defaultValue={startDate} type="date" onChange={(e) => setStartDate(e.target.value)} />
                            </td>
                        </tr>
                        <tr>
                            <td>
                                End Date:
                            </td>
                            <td>
                                <input defaultValue={endDate} type="date" onChange={(e) => setEndDate(e.target.value)} />
                            </td>
                        </tr>
                        <tr style={{ lineHeight: 0 }}>
                            <td>

                            </td>
                            <td>
                                <button onClick={() => callingFilteredSMS()}>Search</button>
                            </td>
                        </tr>
                    </table>
                    Configuration
                    <button onClick={openModal} style={{ marginLeft: 10 }}>Open Setting</button>
                    <br />
                    Receipt
                    <button onClick={openModal1} style={{ marginLeft: 10 }}>History</button>
                    <br />
                </div>
            </div>
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <div>
                <table className="table table-bordered" id="table-main">
                    <thead>
                        <tr>
                            <th scope="col" style={{ fontWeight: "bold", padding: 5, verticalAlign: "middle" }}>S. No</th>
                            <th scope="col" style={{ fontWeight: "bold", padding: 5, verticalAlign: "middle" }}>UID</th>
                            <th scope="col" style={{ fontWeight: "bold", padding: 5, verticalAlign: "middle" }}>Recipient</th>
                            <th scope="col" style={{ fontWeight: "bold", padding: 5, verticalAlign: "middle" }}>Message</th>
                            <th scope="col" style={{ fontWeight: "bold", padding: 5, verticalAlign: "middle" }}>Type</th>
                            <th scope="col" style={{ fontWeight: "bold", padding: 5, verticalAlign: "middle" }}>URL</th>
                            <th scope="col" style={{ fontWeight: "bold", padding: 5, verticalAlign: "middle" }}>Status</th>
                            <th scope="col" style={{ fontWeight: "bold", padding: 5, verticalAlign: "middle" }}>Time</th>
                        </tr>
                    </thead>
                    <tbody>
                        {messages.map((v, i) => {
                            return v.UID && <tr style={{ backgroundColor: ((i + 1) % 2) !== 0 ? "#F5F5F5" : "white" }}>
                                <th scope="row" style={{ fontWeight: "bold", padding: 5, verticalAlign: "middle" }}>{(i + 1).toString().length < 2 ? "0" + (i + 1) : (i + 1)}</th>
                                <td style={{ fontWeight: "bold", padding: 5, textAlign: "left", verticalAlign: "middle" }}>{v.UID}</td>
                                <td style={{ fontWeight: "bold", padding: 5, textAlign: "left", verticalAlign: "middle" }}>{v.recipient}</td>
                                <td style={{ fontWeight: "bold", padding: 5, textAlign: "left", verticalAlign: "middle" }}>{v.message}</td>
                                <td style={{ verticalAlign: "middle", padding: 5, textAlign: "left", fontWeight: "bold" }}>{v.type}</td>
                                <td style={{ fontWeight: "bold", padding: 5, textAlign: "left", verticalAlign: "middle" }}>{v.url ? v.url : ""}</td>
                                <td style={{ fontWeight: "bold", padding: 5, textAlign: "left", verticalAlign: "middle" }}>{v.status == 0 ? "Not sent" : "Sent"}</td>
                                <td style={{ fontWeight: "bold", padding: 5, textAlign: "left", verticalAlign: "middle" }}>{v.time}</td>
                            </tr>
                        })}
                    </tbody>
                </table>
            </div>
            <Modal
                isOpen={modalIsOpen}
                // onAfterOpen={func}
                onRequestClose={closeModal}
                style={customStyles}
                contentLabel="Setting"
            >
                <div>
                    <h4>System Configuration</h4>
                    <div style={{ border: "1px solid lightgrey", borderRadius: 5, padding: 10 }}>
                        <h5>Account Details</h5>
                        <p id="details">Account: {clientData[0].Client_Name}</p>
                        <br />
                        <p id="details">Active (API): {clientData[0].Is_Activate == 0 ? "No" : "Yes"}</p>
                        <br />
                        <p id="details">Test WhatsApp Remaining: {clientData[0].Test_WhatsApp_Remaining}</p>
                        <br />
                        <p id="details">Rate per WhatsApp: {clientData[0].Whatsapp_Rate + " PKR"}</p>
                        <br />
                        <p id="details">Credit: {clientData[0].Account_Balance + " PKR"}</p>
                        <br />
                        <p id="details" style={{ display: "inline-block" }}>WhatsApp Number: </p>
                        <input id="details" type="text" style={{ display: "inline-block" }}
                            value={whatsappNumber ? whatsappNumber : clientData[0].WhatsApp_Number} onChange={(e) => setWhatsappNumber(e.target.value)}
                        />
                        <button id="details" style={{ lineHeight: 0 }} disabled={clientData[0].WhatsApp_Number ? true : false} onClick={() => saveNumber()}>Save</button>
                        <br />
                    </div>
                    <br />
                    <div style={{ border: "1px solid lightgrey", borderRadius: 5, padding: 10 }}>
                        <h5 style={{ marginBottom: 12 }}>System Configuration</h5>
                        <p id="details">UID: {clientData[0].UID}</p>
                        <br />
                        <p id="details">API: {clientData[0].WhatsApp_Number
                            ?
                            `https://whatsapp.dodaily.pk/app/v1/messages?UID=${clientData[0].UID}`
                            :
                            `WhatsApp no. is needed for API, number can't be changed once entered`
                        }</p>
                        <p id="details" style={{ fontStyle: "italic", fontWeight: "bold" }}>Note: The frequency of calling API is 60 times an hour.</p>
                    </div>
                    <br />
                    <div style={{ border: "1px solid lightgrey", borderRadius: 5, padding: 10 }}>
                        <h5 style={{ marginBottom: 12, display: "inline-block" }}>Secret Token Creation</h5> {clientData[0].tokenTime && <p style={{ display: "inline-block", fontSize: 12, fontStyle: "italic" }}>(Last created on {clientData[0].tokenTime})</p>}
                        <br />
                        <input id="details" value={password} placeholder="Please enter password" type="password" onChange={(e) => setPassword(e.target.value)} />
                        <button id="details" onClick={() => createToken()}>Create Token</button>
                        <br />
                        <br />
                        {token
                            ?
                            tokenTemplate
                            :
                            ""
                        }
                    </div>
                </div>
                <br />
                <button onClick={closeModal}>Close</button>
            </Modal>
            <Modal
                isOpen={modalIsOpen1}
                // onAfterOpen={func}
                onRequestClose={closeModal1}
                style={customStyles}
                contentLabel="Setting"
            >
                <div>
                    <h4>Receipt History</h4>
                    <table style={{ marginBottom: 15 }}>
                        <tr>
                            <td>
                                Start Date:
                            </td>
                            <td>
                                <input defaultValue={startDateReceipt} type="date" onChange={(e) => setStartDateReceipt(e.target.value)} />
                            </td>
                        </tr>
                        <tr>
                            <td>
                                End Date:
                            </td>
                            <td>
                                <input defaultValue={endDateReceipt} type="date" onChange={(e) => setEndDateReceipt(e.target.value)} />
                            </td>
                        </tr>
                        <tr style={{ lineHeight: 0 }}>
                            <td>

                            </td>
                            <td>
                                <button onClick={() => getReceipts()}>Search</button>
                            </td>
                        </tr>
                    </table>
                    <table className="table table-bordered" id="table-main">
                        <thead>
                            <tr>
                                <th scope="col" style={{ fontWeight: "bold", padding: 5, verticalAlign: "middle" }}>S. No</th>
                                <th scope="col" style={{ fontWeight: "bold", padding: 5, verticalAlign: "middle" }}>UID</th>
                                <th scope="col" style={{ fontWeight: "bold", padding: 5, verticalAlign: "middle" }}>Amount</th>
                                <th scope="col" style={{ fontWeight: "bold", padding: 5, verticalAlign: "middle" }}>Date</th>
                            </tr>
                        </thead>
                        <tbody>
                            {receipts.map((v, i) => {
                                return v.UID && <tr style={{ backgroundColor: ((i + 1) % 2) !== 0 ? "#F5F5F5" : "white" }}>
                                    <th scope="row" style={{ fontWeight: "bold", padding: 5, verticalAlign: "middle" }}>{(i + 1).toString().length < 2 ? "0" + (i + 1) : (i + 1)}</th>
                                    <td style={{ fontWeight: "bold", padding: 5, textAlign: "left", verticalAlign: "middle" }}>{v.UID}</td>
                                    <td style={{ fontWeight: "bold", padding: 5, textAlign: "left", verticalAlign: "middle" }}>{v.Value + " PKR"}</td>
                                    <td style={{ fontWeight: "bold", padding: 5, textAlign: "left", verticalAlign: "middle" }}>{v.Receipt_Time}</td>
                                </tr>
                            })}
                        </tbody>
                    </table>
                </div>
                <button onClick={closeModal1}>Close</button>
            </Modal>
        </div>
    )
}