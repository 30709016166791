import { Guide } from "./pages/guide"
import { Dashboard } from "./pages/dashboard"
import { Error } from "./pages/404"
import { Pricing } from "./pages/pricing"
import { Login } from "./pages/Login"
import { Signup } from "./pages/signup"
import { Admin } from "./pages/admin"

export const nav = [
    { path: "/", name: "Login", element: <Login />, isMenu: false, isPrivate: false },
    { path: "/signup", name: "Sign Up", element: <Signup />, isMenu: false, isPrivate: false },
    { path: "/dashboard", name: "Dashboard", element: <Dashboard />, isMenu: true, isPrivate: true, type: "client" },
    { path: "/pricing", name: "Pricing", element: <Pricing />, isMenu: true, isPrivate: false },
    { path: "/guide", name: "Guide", element: <Guide />, isMenu: true, isPrivate: true, type: "client" },
    { path: "*", name: null, element: <Error />, isMenu: true, isPrivate: false },
    { path: "/admin", name: "Admin", element: <Admin />, isMenu: true, isPrivate: true, type: "admin" },
] 