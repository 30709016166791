import axios from "axios"
import { useState } from "react"
import { useNavigate } from "react-router-dom"
import { MDBBtn, MDBInput } from 'mdb-react-ui-kit'
import '../css/login.css'

export const Signup = () => {

     const navigate = useNavigate()
     const [user, setUser] = useState({ name: null, email: null, password: null })
     const [errorMessage, setErrorMessage] = useState(null)

     var regexForName = /^[a-zA-Z0-9]{8,}$/
     var emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
     var passwordRegex = /^.{8,}$/

     const error1 = (
          <div>
               <p>Please check if credentials match the following:</p>
               <ul>
                    <li>Username cannot contain special characters</li>
                    <li>Username length must be a minimum of 8</li>
                    <li>Password length must be a minimum of 8</li>
               </ul>
          </div>
     )

     const doLogin = () => {
          try {
               if (regexForName.test(user.name) && emailRegex.test(user.email) && passwordRegex.test(user.password)) {
                    axios.post("https://whatsapp.dodaily.pk/app/register", {
                         user
                    }).then((response) => {
                         if (response.data.error) {
                              setErrorMessage(response.data.error.code)
                         }
                         else {
                              alert("successfull registration")
                              navigate("/")
                         }
                    })
               }
               else {
                    setErrorMessage(error1)
               }
          } catch (error) {
               setErrorMessage(error)
          }
     }

     return (
          <div style={{ display: "inline-block", flex: 1, verticalAlign: "top", fontFamily: "calibri", alignContent: "center" }}>
               <div className="loginDiv"
                    style={{
                         border: "1px solid lightgrey",
                         borderRadius: 5,
                         margin: "100px auto",
                    }}>
                    <br />

                    <div className="d-flex flex-row align-items-center justify-content-center">

                         <p className="lead fw-normal mb-0 me-3">Sign up</p>

                    </div>
                    <br />

                    <MDBInput wrapperClass='mb-4' label='User Name' id='formControlLg1' size="lg" value={user.name} onChange={(e) => setUser({ ...user, name: e.target.value })} type="text" />
                    <MDBInput wrapperClass='mb-4' label='Email' id='formControlLg2' size="lg" value={user.email} onChange={(e) => setUser({ ...user, email: e.target.value })} type="text" />
                    <MDBInput wrapperClass='mb-4' label='Password' id='formControlLg3' type='password' size="lg" value={user.password} onChange={(e) => setUser({ ...user, password: e.target.value })} />

                    {/* <div className="d-flex justify-content-between mb-4">
                              <MDBCheckbox name='flexCheck' value='' id='flexCheckDefault' label='Remember me' />
                              <a href="!#">Forgot password?</a>
                         </div> */}

                    <div className='text-center text-md-start mt-4 pt-2'>
                         <MDBBtn className="mb-0 px-5" size='lg' style={{ backgroundColor: "#075e54" }} onClick={doLogin}>Register</MDBBtn>
                    </div>
                    <br />
                    {errorMessage ?
                         <div className="error">{errorMessage}</div>
                         : null}
                    <br />

               </div>
          </div>
     )
}