import { createContext, useContext, useState, useEffect } from "react"
import { RenderMenu, RenderRoutes } from "../components/RenderNavigation"
import axios from "axios"
import { useNavigate } from "react-router-dom"

const AuthContext = createContext()

export const AuthData = () => useContext(AuthContext)

export const AuthWrapper = () => {

    // axios.defaults.withCredentials = true

    const navigate = useNavigate()

    const [user, setUser] = useState({ name: "", token: "", isAuthenticated: false, type: null })

    const userAuthenticeted = () => {
        axios.get("https://whatsapp.dodaily.pk/app/isUserAuth", {
            headers: {
                "x-access-token": localStorage.getItem("token"),
                "auth": localStorage.getItem("auth")
            },
        }).then((response) => {
            if (response.data.auth) {
                setUser({ ...user, token: response.data.token, isAuthenticated: response.data.auth, type: response.data.type })
            }
        })
    }

    useEffect(() => {
        // axios.get("https://whatsapp.dodaily.pk/app/login", {
        //     // withCredentials: "include",
        // }).then((response) => {
        //     // console.log("login" + JSON.stringify(response.data))
        //     if (response.data.loggedIn === true) {

        //     }
        // })
        userAuthenticeted()
    }, []);

    const login = (userName, password) => {
        return new Promise((resolve, reject) => {
            axios.post("https://whatsapp.dodaily.pk/app/login", {
                // withCredentials: "include",
                Email: userName,
                Password: password,
            }).then((response) => {
                if (!response.data.auth) {
                    reject(response.data.message)
                } else {
                    localStorage.setItem("token", response.data.token)
                    localStorage.setItem("auth", response.data.uid)
                    setUser({ token: response.data.token, isAuthenticated: response.data.auth, type: response.data.type })
                    resolve({ type: response.data.type })
                }
            })
        })
    }

    const logout = () => {
        axios.post("https://whatsapp.dodaily.pk/app/logout", {
            headers: {
                "x-access-token": null,
            },
        }).then((response) => {
            if (!response.data.auth) {
                setUser({ ...user, isAuthenticated: response.data.auth, token: response.data.token, type: null })
                localStorage.setItem("token", response.data.token)
                localStorage.setItem("auth", false)
                navigate("/")
            }
        })
    }

    return (
        <AuthContext.Provider value={{ user, login, logout }}>
            <>
                {/* <RenderHeader /> */}
                <RenderMenu />
                <RenderRoutes />
            </>
        </AuthContext.Provider>
    )
}