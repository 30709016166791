import { useNavigate } from "react-router-dom"
import { AuthData } from "../../auth/AuthWrapper"

export const Error = () => {
    const { user } = AuthData();
    const navigate = useNavigate()
    const dash = () => {
        navigate("/dashboard")
    }
    const log = () => {
        navigate("/")
    }
    return <div>
        <h2>404 error. Page not found. </h2>
        <p>
            {user.isAuthenticated
                ?
                <p>Go to <span style={{ cursor: "pointer" }} onClick={() => dash()}>Dashboard</span></p>
                :
                <p>Go to <span style={{ cursor: "pointer" }} onClick={() => log()}>Login</span></p>
            }
        </p>
    </div>
}