import { useNavigate } from "react-router-dom"
import { AuthData } from "../../auth/AuthWrapper"
import "../css/admin.css"
import { useEffect, useState } from "react"
import axios from "axios"
import Modal from 'react-modal'
import formdate from 'date-and-time'
import { saveAs } from 'file-saver'
import Switch from "@mui/material/Switch"

export const Admin = () => {

    Modal.setAppElement(Admin)
    Modal.setAppElement('#root')

    const { user } = AuthData()
    const navigate = useNavigate()
    const [users, setUsers] = useState([{}])
    const [modalIsOpen, setIsOpen] = useState(false)
    const [modalIsOpen1, setIsOpen1] = useState(false)
    const [editUser, setEditUser] = useState({})
    const [configUser, setConfigUser] = useState({})
    const [whatsappList, setWhatsappList] = useState([{}])
    const [stats, setStats] = useState([{}])
    const [startDate, setStartDate] = useState()
    const [endDate, setEndDate] = useState()
    const [startDateReceipt, setStartDateReceipt] = useState()
    const [endDateReceipt, setEndDateReceipt] = useState()
    const [PDFDetailed, setPDFDetailed] = useState()
    const [PDFInvoice, setPDFInvoice] = useState()
    const [receipt, setReceipt] = useState({ UID: "", value: "" })
    const [receiptUser, setReceiptUser] = useState()
    const [receipts, setReceipts] = useState([{}])

    const count = 1

    function changeTimeZone(date, timeZone) {
        if (typeof date === 'string') {
            return new Date(
                new Date(date).toLocaleString('en-US', {
                    timeZone,
                }),
            )
        }

        return new Date(
            date.toLocaleString('en-US', {
                timeZone,
            }),
        )
    }

    const currentDate = new Date()
    const khiDate = changeTimeZone(currentDate, 'Asia/Karachi')
    let endDatee = formdate.format(khiDate, 'YYYY-MM-DD')

    currentDate.setDate(1)
    let startDatee = formdate.format(currentDate, 'YYYY-MM-DD')

    const customStyles = {
        content: {
            top: '40%',
            left: '50%',
            right: '20%',
            bottom: '-10%',
            marginRight: '-20%',
            transform: 'translate(-50%, -50%)',
        },
    }

    function openModal() {
        setIsOpen(true)
    }

    function closeModal() {
        setIsOpen(false)
    }

    function openModal1() {
        setIsOpen1(true)
    }

    function closeModal1() {
        setIsOpen1(false)
    }

    function callingClients() {
        axios.get("https://whatsapp.dodaily.pk/app/getClients")
            .then((res) => {
                // console.log(res.data.users)
                setUsers(res.data.users)
            })
    }

    useEffect(() => {
        callingClients()
        setStartDate(startDatee)
        setEndDate(endDatee)
        setStartDateReceipt(startDatee)
        setEndDateReceipt(endDatee)
    }, [count])

    function funcEditUser(v) {
        setEditUser(v)
        openModal()
    }

    function funcConfig(v) {
        setConfigUser(v)
        openModal1()
    }

    function countFilter(v) {
        var arr = []
        var temp = 0
        users.map((v1, i1) => {
            v.map((v2, i2) => {
                if (v2.Client_Name == v1.Client_Name) {
                    temp = temp + 1
                }
            })
            arr.push({ [v1.Client_Name]: temp });
            temp = 0
        })
        setStats(arr)
    }

    function callingFilteredSMS() {
        var filteredSuccess
        let data = {
            startDate: startDate,
            endDate: endDate
        }
        axios.post("https://whatsapp.dodaily.pk/app/filteredsms", {
            data
        })
            .then(res => {
                filteredSuccess = res.data
                    .map((obj) => ({
                        ...obj
                    }))
                    .sort((a, b) => {
                        const dateA = new Date(a.timestamp)
                        const dateB = new Date(b.timestamp)

                        return dateB - dateA; // Ascending order
                    })
                setWhatsappList(filteredSuccess)
                countFilter(filteredSuccess)
            })
    }

    const handleGeneratePDF = () => {
        let PDFInvClient = PDFInvoice
        users.map((v1, i1) => {
            if (v1.Client_Name === PDFInvClient) {
                stats.map((v2, i2) => {
                    if (Object.keys(v2)[0] === PDFInvClient) {
                        generatePDF({ v1, whatsappCount: Object.values(v2)[0] })
                        // console.log({ v1, whatsappCount: Object.values(v2)[0] })
                    }
                })
            }
        })
    }

    const handleGenerateList = () => {
        let temphandle = []
        let PDFListClient = PDFDetailed
        users.map((v1, i1) => {
            if (v1.Client_Name === PDFListClient) {
                whatsappList.map((v2, i2) => {
                    if (v2.Client_Name === PDFListClient) {
                        temphandle.push(v2)
                    }
                })
                // console.log({ v1, whatsappList: temphandle })
                generatePDFList({ v1, whatsappList: temphandle })
            }
        })
    }

    const generatePDFList = (v) => {
        axios.post("https://whatsapp.dodaily.pk/app/pdflist", {
            v
        })
            .then(res => {
                // console.log(res)
            })
    }

    const generatePDF = (v) => {
        axios.post("https://whatsapp.dodaily.pk/app/pdfinv", { v }, { responseType: 'blob' })
            .then((res) => {
                const url = window.URL.createObjectURL(new Blob([res.data], { type: 'application/pdf' }))

                const link = document.createElement('a')
                link.href = url
                link.setAttribute('download', 'document.pdf')

                document.body.appendChild(link)
                link.click()

                document.body.removeChild(link)
            })
            .catch((error) => {
                console.error('Error generating PDF:', error)
            })
    }

    const saveReceipt = () => {
        let data = {
            UID: receipt.UID,
            value: receipt.value
        }
        axios.post("https://whatsapp.dodaily.pk/app/addReceipt", {
            data
        })
            .then(res => {
                alert(res.data.message)
                setReceipt({ UID: "", value: "" })
                callingClients()
            })
    }

    function saveEditUser(v) {
        let data = {
            UID: v,
            User_Name: editUser.User_Name,
            Client_Name: editUser.User_Name,
            User_Email: editUser.User_Email,
            User_Password: editUser.User_Password,
            Client_Status: editUser.Client_Status,
            // Is_Activate: editUser.Is_Activate,
            Test_WhatsApp_Remaining: editUser.Test_WhatsApp_Remaining,
            WhatsApp_Number: editUser.WhatsApp_Number,
            Whatsapp_Rate: editUser.Whatsapp_Rate,
        }
        axios.post("https://whatsapp.dodaily.pk/app/updateUser", {
            data
        })
            .then(res => {
                if (res.data.error) {
                    console.log(res.data.error)
                    alert(res.data.error.code)
                }
                else {
                    alert(res.data.message)
                    setIsOpen(false)
                    callingClients()
                }
            })
    }

    function saveConfigUser(v) {
        let data = {
            UID: v,
            APP_ID: configUser.APP_ID,
            APP_SECRET: configUser.APP_SECRET,
            VERSION: configUser.VERSION,
            PHONE_NUMBER_ID: configUser.PHONE_NUMBER_ID,
            MESSAGING_PRODUCT: configUser.MESSAGING_PRODUCT,
            WHATSAPP_ACCOUNT_ID: configUser.WHATSAPP_ACCOUNT_ID,
            ACCESS_TOKEN: configUser.ACCESS_TOKEN,
        }
        axios.post("https://whatsapp.dodaily.pk/app/updateServerConfig", {
            data
        })
            .then(res => {
                if (res.data.error) {
                    console.log(res.data.error)
                    alert(res.data.error.code)
                }
                else {
                    alert(res.data.message)
                    setIsOpen1(false)
                    callingClients()
                }
            })
    }

    function getReceipts() {
        try {
            if (receiptUser) {
                axios.post("https://whatsapp.dodaily.pk/app/getReceipts", {
                    UID: receiptUser,
                    startDate: startDateReceipt,
                    endDate: endDateReceipt
                }).then((response) => {
                    if (response.data.data.length > 0) {
                        if (response.data.error) {
                            alert(response.data.error)
                        }
                        else {
                            setReceipts(response.data.data)
                        }
                    }
                    else {
                        setReceipts([{}])
                        alert("No record found in the selected criteria.")
                    }
                })
            }
            else {
                alert("Please select User")
            }
        }
        catch (error) {
            alert(error)
        }
    }

    function setClientStatus(status, UID) {
        if (status == 0) {
            status = 1
        }
        else {
            status = 0
        }
        axios.post('https://whatsapp.dodaily.pk/app/updateClientStatus', {
            status: status,
            UID: UID
        })
            .then((res) => {
                if (res.data.error) {
                    alert(res.data.error.code)
                }
                else {
                    alert(res.data.message)
                    callingClients()
                }
            })
    }

    return <div className="admin">
        <h2>Admin</h2>
        <div style={{ border: "1px solid lightgrey", padding: 10, borderRadius: 5 }}>
            <h4>Users</h4>
            <table className="table table-bordered" id="table-main">
                <thead>
                    <tr>
                        <th scope="col" style={{ fontWeight: "bold", padding: 5, verticalAlign: "middle" }}>S. No</th>
                        <th scope="col" style={{ width: 50, fontWeight: "bold", padding: 5, verticalAlign: "middle" }}>UID</th>
                        <th scope="col" style={{ fontWeight: "bold", padding: 5, verticalAlign: "middle" }}>Name</th>
                        <th scope="col" style={{ fontWeight: "bold", padding: 5, verticalAlign: "middle" }}>Email</th>
                        <th scope="col" style={{ fontWeight: "bold", padding: 5, verticalAlign: "middle" }}>Password</th>
                        {/* <th scope="col" style={{ fontWeight: "bold", padding: 5, verticalAlign: "middle" }}>Type</th> */}
                        <th scope="col" style={{ fontWeight: "bold", padding: 5, verticalAlign: "middle" }}>Status</th>
                        <th scope="col" style={{ fontWeight: "bold", padding: 5, verticalAlign: "middle" }}>Active</th>
                        <th scope="col" style={{ fontWeight: "bold", padding: 5, verticalAlign: "middle" }}>Free Quota</th>
                        <th scope="col" style={{ fontWeight: "bold", padding: 5, verticalAlign: "middle" }}>WhatsApp No.</th>
                        <th scope="col" style={{ fontWeight: "bold", padding: 5, verticalAlign: "middle" }}>Rate</th>
                        <th scope="col" style={{ fontWeight: "bold", padding: 5, verticalAlign: "middle" }}>Credit</th>
                        <th scope="col" style={{ fontWeight: "bold", padding: 5, verticalAlign: "middle" }}>Time</th>
                        <th scope="col" style={{ fontWeight: "bold", padding: 5, verticalAlign: "middle" }}>Action</th>
                    </tr>
                </thead>
                <tbody>
                    {users.map((v, i) => {
                        return <tr style={{ backgroundColor: ((i + 1) % 2) !== 0 ? "#F5F5F5" : "white" }}>
                            <th scope="row" style={{ fontWeight: "bold", padding: 5, verticalAlign: "middle" }}>{(i + 1).toString().length < 2 ? "0" + (i + 1) : (i + 1)}</th>
                            <td style={{ maxWidth: 150, fontWeight: "bold", padding: 5, textAlign: "left", verticalAlign: "middle", overflowX: "scroll" }}>{v.UID}</td>
                            <td style={{ fontWeight: "bold", padding: 5, textAlign: "left", verticalAlign: "middle" }}>{v.User_Name}</td>
                            <td style={{ fontWeight: "bold", padding: 5, textAlign: "left", verticalAlign: "middle" }}>{v.User_Email}</td>
                            <td style={{ fontWeight: "bold", padding: 5, textAlign: "left", verticalAlign: "middle" }}>{v.User_Password}</td>
                            {/* <td style={{ fontWeight: "bold", padding: 5, textAlign: "left", verticalAlign: "middle" }}>{v.User_Type}</td> */}
                            <td style={{ fontWeight: "bold", padding: 5, textAlign: "left", verticalAlign: "middle" }}>{v.Client_Status == 1 ? "On" : "Off"}</td>
                            <td style={{ fontWeight: "bold", padding: 5, textAlign: "left", verticalAlign: "middle" }}>
                                {v.Is_Activate == 1 ? "Yes" : "No"}
                                <Switch size="small" checked={v.Is_Activate == 1 ? "checked" : ""} value={1} onChange={() => setClientStatus(v.Is_Activate, v.UID)}>

                                </Switch>
                            </td>
                            <td style={{ fontWeight: "bold", padding: 5, textAlign: "left", verticalAlign: "middle" }}>{v.Test_WhatsApp_Remaining}</td>
                            <td style={{ fontWeight: "bold", padding: 5, textAlign: "left", verticalAlign: "middle" }}>{v.WhatsApp_Number}</td>
                            <td style={{ fontWeight: "bold", padding: 5, textAlign: "left", verticalAlign: "middle" }}>{v.Whatsapp_Rate}</td>
                            <td style={{ fontWeight: "bold", padding: 5, textAlign: "left", verticalAlign: "middle" }}>{v.Account_Balance}</td>
                            <td style={{ fontWeight: "bold", padding: 5, textAlign: "left", verticalAlign: "middle" }}>{v.time}</td>
                            <td style={{ fontWeight: "bold", padding: 5, textAlign: "left", verticalAlign: "middle" }}><button style={{ border: "1px solid black" }} onClick={() => funcEditUser(v)}>Edit</button><button style={{ border: "1px solid black" }} onClick={() => funcConfig(v)}>Config</button></td>
                        </tr>
                    })}
                </tbody>
            </table>
        </div>
        <br />
        <div style={{ border: "1px solid lightgrey", padding: 10, borderRadius: 5 }}>
            <h4>Add Receipt</h4>
            <select style={{ border: "1px solid lightgrey", height: 25, borderRadius: 3, fontSize: 12, width: 100, marginBottom: 10 }} onChange={(e) => setReceipt({ ...receipt, UID: e.target.value })} name="receipt" id="receipt">
                <option>Select</option>
                {
                    users.map((v, i) => {
                        return <option key={i} value={v.UID}>{v.Client_Name + " | " + v.UID}</option>
                    })
                }
            </select>
            <br />
            <input style={{ border: "1px solid lightgrey", height: 25, borderRadius: 3, fontSize: 12, width: 100, marginBottom: 10 }} placeholder={"PKR"} onChange={(e) => setReceipt({ ...receipt, value: e.target.value })} value={receipt.value} />
            <br />
            <button onClick={() => saveReceipt()}>Add</button>
        </div>
        <br />
        <div style={{ border: "1px solid lightgrey", padding: 10, borderRadius: 5 }}>
            <h4>Receipt History</h4>
            <table>
                <tr>
                    <td>
                        Select User:
                    </td>
                    <td>
                        <select style={{ width: 130 }} onChange={(e) => setReceiptUser(e.target.value)} name="receipt" id="receipt">
                            <option>Select</option>
                            {
                                users.map((v, i) => {
                                    return <option key={i} value={v.UID}>{v.Client_Name + " | " + v.UID}</option>
                                })
                            }
                        </select>
                    </td>
                </tr>
                <tr>
                    <td>
                        Start Date:
                    </td>
                    <td>
                        <input defaultValue={startDateReceipt} type="date" onChange={(e) => setStartDateReceipt(e.target.value)} />
                    </td>
                </tr>
                <tr>
                    <td>
                        End Date:
                    </td>
                    <td>
                        <input defaultValue={endDateReceipt} type="date" onChange={(e) => setEndDateReceipt(e.target.value)} />
                    </td>
                </tr>
                <tr style={{ lineHeight: 0 }}>
                    <td>

                    </td>
                    <td>
                        <button onClick={() => getReceipts()}>Search</button>
                    </td>
                </tr>
            </table>
            <br />
            <table className="table table-bordered" id="table-main">
                <thead>
                    <tr>
                        <th scope="col" style={{ fontWeight: "bold", padding: 5, verticalAlign: "middle" }}>S. No</th>
                        <th scope="col" style={{ fontWeight: "bold", padding: 5, verticalAlign: "middle" }}>UID</th>
                        <th scope="col" style={{ fontWeight: "bold", padding: 5, verticalAlign: "middle" }}>Amount</th>
                        <th scope="col" style={{ fontWeight: "bold", padding: 5, verticalAlign: "middle" }}>Date</th>
                    </tr>
                </thead>
                <tbody>
                    {receipts.map((v, i) => {
                        return v.UID && <tr style={{ backgroundColor: ((i + 1) % 2) !== 0 ? "#F5F5F5" : "white" }}>
                            <th scope="row" style={{ fontWeight: "bold", padding: 5, verticalAlign: "middle" }}>{(i + 1).toString().length < 2 ? "0" + (i + 1) : (i + 1)}</th>
                            <td style={{ fontWeight: "bold", padding: 5, textAlign: "left", verticalAlign: "middle" }}>{v.UID}</td>
                            <td style={{ fontWeight: "bold", padding: 5, textAlign: "left", verticalAlign: "middle" }}>{v.Value + " PKR"}</td>
                            <td style={{ fontWeight: "bold", padding: 5, textAlign: "left", verticalAlign: "middle" }}>{v.Receipt_Time}</td>
                        </tr>
                    })}
                </tbody>
            </table>
        </div>
        <br />
        <div style={{ border: "1px solid lightgrey", padding: 10, borderRadius: 5 }}>
            <h4>Statistics</h4>
            <table>
                <tr>
                    <td>
                        Start:
                    </td>
                    <td>
                        <input type="date"
                            defaultValue={startDate}
                            onChange={e =>
                                setStartDate(e.target.value)
                            }
                        />
                    </td>
                </tr>
                <tr>
                    <td>
                        End:
                    </td>
                    <td>
                        <input type="date"
                            defaultValue={endDate}
                            onChange={e =>
                                setEndDate(e.target.value)
                            }
                        />
                    </td>
                </tr>
                <tr>
                    <td>

                    </td>
                    <td>
                        <button
                            onClick={() => callingFilteredSMS()}
                        >Search</button>
                    </td>
                </tr>
            </table>
            <div>
                <br />
                {whatsappList.length > 1 &&
                    <table className="table table-bordered table-sm" id="table-stats">
                        <thead>
                            <tr>
                                {stats.map((v, i) => {
                                    return <>
                                        <td scope="row" style={{ width: 200, fontWeight: "bold" }}>{Object.keys(v)}</td>
                                    </>
                                })}
                            </tr>
                            <tr>
                                {stats.map((v, i) => {
                                    return <>
                                        <td scope="row" style={{ width: 200, fontWeight: "bold" }}>{Object.values(v)}</td>
                                    </>
                                })}
                            </tr >
                        </thead>
                    </table>
                }
                <table>
                    <thead>
                        <tr>
                            <th>Client's Name</th>
                            <th>Recipient</th>
                            <th>Message</th>
                            <th>Type</th>
                            <th>Status</th>
                            <th>Time</th>
                        </tr>
                    </thead>
                    {whatsappList.map((v, i) => {
                        return v.UID && <>
                            <tbody>
                                <tr key={i + 1} >
                                    <td>{v.Client_Name}</td>
                                    <td>{v.recipient}</td>
                                    <td>{v.message}</td>
                                    <td>{v.type}</td>
                                    <td>{v.status == 0 ? "Pending" : "Sent"}</td>
                                    <td>{v.time}</td>
                                </tr>
                            </tbody>
                        </>
                    })}
                </table>
            </div>
            <br />
            <div>
                <table>
                    <tr>
                        <td>
                            <button
                                disabled={whatsappList[0].UID ? false : true}
                                onClick={() => handleGeneratePDF()}
                            >Export Invoice PDF for </button>
                        </td>
                        <td>
                            <select
                                onChange={(e) =>
                                    setPDFInvoice(e.target.value)}
                            >
                                <option value={null}>Select</option>
                                {
                                    users.map((v, i) => {
                                        return <option key={i} value={v.Client_Name}>{v.Client_Name + " | " + v.UID}</option>
                                    })
                                }
                            </select>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <button
                                disabled={whatsappList[0].UID ? false : true}
                                onClick={() => handleGenerateList()}
                            >Export Detailed PDF for </button>
                        </td>
                        <td>
                            <select
                                onChange={(e) =>
                                    setPDFDetailed(e.target.value)
                                }
                            >
                                <option value={null}>Select</option>
                                {
                                    users.map((v, i) => {
                                        return <option key={i} value={v.Client_Name}>{v.Client_Name + " | " + v.UID}</option>
                                    })
                                }
                            </select>
                        </td>
                    </tr>
                </table>
                <br />
            </div>
            <br />
        </div>
        <Modal
            isOpen={modalIsOpen}
            // onAfterOpen={func}
            onRequestClose={closeModal}
            style={customStyles}
            contentLabel="Setting"
        >
            <div>
                <h4>Edit User</h4>
                <div style={{ border: "1px solid lightgrey", borderRadius: 5, padding: 10 }}>
                    <p id="details">UID: {editUser.UID}</p>
                    <br />
                    <p id="details">Name:</p>
                    <input id="details" type="text" style={{ display: "inline-block" }}
                        value={editUser.User_Name}
                        onChange={(e) => setEditUser({ ...editUser, User_Name: e.target.value })}
                    />
                    <br />
                    <p id="details">Email:</p>
                    <input id="details" type="text" style={{ display: "inline-block" }}
                        value={editUser.User_Email}
                        onChange={(e) => setEditUser({ ...editUser, User_Email: e.target.value })}
                    />
                    <br />
                    <p id="details">Password:</p>
                    <input id="details" type="text" style={{ display: "inline-block" }}
                        value={editUser.User_Password}
                        onChange={(e) => setEditUser({ ...editUser, User_Password: e.target.value })}
                    />
                    <br />
                    <p id="details">Type: {editUser.User_Type}</p>
                    <br />
                    <p id="details">Status (Test Messaging):</p>
                    <select
                        onChange={(e) => setEditUser({ ...editUser, Client_Status: e.target.value })}
                    >
                        <option value={1} selected={editUser.Client_Status == 1 ? true : false}>On</option>
                        <option value={0} selected={editUser.Client_Status == 0 ? true : false}>Off</option>
                    </select>
                    <br />
                    <p id="details">Active (API):</p>
                    {editUser.Is_Activate == 1 ? "Yes" : "No"}
                    {/* <select
                        onChange={(e) => setEditUser({ ...editUser, Is_Activate: e.target.value })}
                    >
                        <option value={1} selected={editUser.Is_Activate == 1 ? true : false}>Yes</option>
                        <option value={0} selected={editUser.Is_Activate == 0 ? true : false}>No</option>
                    </select> */}
                    <br />
                    <p id="details">Test WhatsApp Remaining:</p>
                    <input id="details" type="text" style={{ display: "inline-block" }}
                        value={editUser.Test_WhatsApp_Remaining}
                        onChange={(e) => setEditUser({ ...editUser, Test_WhatsApp_Remaining: e.target.value })}
                    />
                    <br />
                    <p id="details" style={{ display: "inline-block" }}>WhatsApp Number: </p>
                    <input id="details" type="text" style={{ display: "inline-block" }}
                        value={editUser.WhatsApp_Number ? editUser.WhatsApp_Number : ""}
                        onChange={(e) => setEditUser({ ...editUser, WhatsApp_Number: e.target.value })}
                    />
                    <br />
                    <p id="details">Rate per WhatsApp (PKR):</p>
                    <input id="details" type="text" style={{ display: "inline-block" }}
                        value={editUser.Whatsapp_Rate}
                        onChange={(e) => setEditUser({ ...editUser, Whatsapp_Rate: e.target.value })}
                    />
                    <br />
                    <p id="details">Credit: {editUser.Account_Balance}</p>
                    <br />
                    <p id="details">Account Creation Time: {editUser.time}</p>
                    <br />
                    <p id="details">Last Edit Time: {editUser.editTime ? editUser.editTime : ""}</p>
                    <br />
                    <button id="details" style={{ lineHeight: 0 }} onClick={() => saveEditUser(editUser.UID)}>Save</button>
                    <br />
                </div>
            </div>
            <br />
            <button onClick={closeModal}>Close</button>
        </Modal>
        <Modal
            isOpen={modalIsOpen1}
            // onAfterOpen={func}
            onRequestClose={closeModal1}
            style={customStyles}
            contentLabel="Setting"
        >
            <div>
                <h4>Server Configuration</h4>
                <div style={{ border: "1px solid lightgrey", borderRadius: 5, padding: 10 }}>
                    <table>
                        <tr>
                            <td>
                                <p id="details">APP_ID:</p>
                            </td>
                            <td>
                                <input id="details" type="text" style={{ display: "inline-block" }}
                                    value={configUser.APP_ID}
                                    onChange={(e) => setConfigUser({ ...configUser, APP_ID: e.target.value })}
                                />
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <p id="details">APP_SECRET:</p>
                            </td>
                            <td>
                                <input id="details" type="text" style={{ display: "inline-block" }}
                                    value={configUser.APP_SECRET}
                                    onChange={(e) => setConfigUser({ ...configUser, APP_SECRET: e.target.value })}
                                />
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <p id="details">VERSION:</p>
                            </td>
                            <td>
                                <select
                                    onChange={(e) => setConfigUser({ ...configUser, VERSION: e.target.value })}
                                >
                                    <option value={"v17.0"} selected={configUser.VERSION == "v17.0" ? true : false}>v17.0</option>
                                    <option value={"v18.0"} selected={configUser.VERSION == "v18.0" ? true : false}>v18.0</option>
                                    <option value={"v19.0"} selected={configUser.VERSION == "v19.0" ? true : false}>v19.0</option>
                                </select>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <p id="details">PHONE_NUMBER_ID:</p>
                            </td>
                            <td>
                                <input id="details" type="text" style={{ display: "inline-block" }}
                                    value={configUser.PHONE_NUMBER_ID}
                                    onChange={(e) => setConfigUser({ ...configUser, PHONE_NUMBER_ID: e.target.value })}
                                />
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <p id="details">MESSAGING_PRODUCT:</p>
                            </td>
                            <td>
                                <input id="details" type="text" style={{ display: "inline-block" }}
                                    value={configUser.MESSAGING_PRODUCT}
                                    onChange={(e) => setConfigUser({ ...configUser, MESSAGING_PRODUCT: e.target.value })}
                                />
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <p id="details" style={{ display: "inline-block" }}>WHATSAPP_ACCOUNT_ID: </p>
                            </td>
                            <td>
                                <input id="details" type="text" style={{ display: "inline-block" }}
                                    value={configUser.WHATSAPP_ACCOUNT_ID ? configUser.WHATSAPP_ACCOUNT_ID : ""}
                                    onChange={(e) => setConfigUser({ ...configUser, WHATSAPP_ACCOUNT_ID: e.target.value })}
                                />
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <p id="details">ACCESS_TOKEN:</p>
                            </td>
                            <td>
                                <input id="details" type="text" style={{ display: "inline-block" }}
                                    value={configUser.ACCESS_TOKEN}
                                    onChange={(e) => setConfigUser({ ...configUser, ACCESS_TOKEN: e.target.value })}
                                />
                            </td>
                        </tr>
                        <tr>
                            <td>

                            </td>
                            <td>
                                <button id="details" style={{ lineHeight: 0 }} onClick={() => saveConfigUser(configUser.UID)}>Save</button>
                            </td>
                        </tr>
                    </table>
                </div>
            </div>
            <br />
            <button onClick={closeModal1}>Close</button>
        </Modal>
    </div>
}